import React, { Component } from 'react';

import Accordeon from '../Accordeon';
import * as Filters from './filters';
import * as Sortings from './sortings';
import SidebarView from './SidebarView';
import GoogleMapView from './GoogleMapView';
import FilterView from './FilterView';
import Tabs from '../Tabs/Tabs';
import Projekte from './Projekte';
import { getQueryVariable } from '../../getQueryVariable';

const TEAMASSISTENT_BAUBUERO = 37;
const MITARBEITER_SEKRETERIAT = 38;
const MITARBEITER_SEKRETERIAT_STANDORT = 47;
const STANDORTLEITER = 22;
const REGIONALVERANTWORTLICHER = 20;
const BUEROLEITER = 45;
const MITARBEITER_IN_OEFFENTLICHKEITSARBEIT = 35;

export const STANDORT_TYP_HAUPTBUERO = 'Hauptbüro';
export const STANDORT_TYP_STANDORT = 'Standort';
export const STANDORT_TYP_BAUBUERO = 'Baubüro';
export const TAB_ANSPRECHPARTNER = 'Ansprechpartner';
export const TAB_BAUBUEROS = 'Baubüros';
export const TAB_PROJEKTE = 'Projekte';

export default class extends Component {
    state = {
        // state
        activeStandortId: '', // the selected standort id
        searchTerm: '', // the currently performed search
        // data
        standorte: [],
        projekte: [],
        projekte_sort: [],
        activeTab: TAB_ANSPRECHPARTNER,
        dataLoaded: false,
    };

    async componentDidMount() {
        const response = await fetch(process.env.STANDORTE_MAP_WIDGET_DATA_URL);
        const json = await response.json();

        const standorte = json.standorte.map(standort => ({
            ...standort,
            lat: parseFloat(standort.lat),
            lng: parseFloat(standort.lng),
            isBaubuero: standort.type === 'Baubüro'
        }));

        // determine initial showed standort - basically it is passed as 'query' parameter or take the first one
        const standortQueryVar = getQueryVariable('standort');
        const activeStandort = standortQueryVar && Object.keys(standorte).map(key => standorte[key]).find(standort => standort.name === standortQueryVar) || standorte[0];
        this.setActiveStandort(activeStandort);

        this.setState(state => ({
            ...state,
            ...json,
            standorte,
            dataLoaded: true,
        }));

    }

    getStandortById = (id = this.state.activeStandortId) =>
        this.state.standorte.find(standort => {
            return standort.id === id;
        });

    getStandorte = (filter = standort => true) => this.state.standorte.map(standort => this.getStandortById(standort.id)).filter(filter);

    getMitarbeiterOfStandort = funktion => {
        const mitarbeiter = this.props.widgetData.mitarbeiterNachAktuellenArbeitsKonditionen;
        const standOrtLeiter = [];
        const sekretariatMitarbeiter = [];
        const mitarbeiterInOeffentlichkeitsArbeit = [];

        Object.keys(mitarbeiter).forEach(key => {
            const m_funktion = mitarbeiter[key].funktion;
            if (m_funktion === REGIONALVERANTWORTLICHER || m_funktion === STANDORTLEITER || m_funktion === BUEROLEITER) {
                standOrtLeiter.push(mitarbeiter[key]);
            }
            if (m_funktion === MITARBEITER_SEKRETERIAT || m_funktion === TEAMASSISTENT_BAUBUERO || m_funktion === MITARBEITER_SEKRETERIAT_STANDORT) {
                sekretariatMitarbeiter.push(mitarbeiter[key]);
            }
            if (m_funktion === MITARBEITER_IN_OEFFENTLICHKEITSARBEIT) {
                mitarbeiterInOeffentlichkeitsArbeit.push(mitarbeiter[key]);
            }
        });

        return funktion === STANDORTLEITER
            ? standOrtLeiter.filter(leiter => leiter.parentStandortID === this.getStandortById().id || leiter.standortName === this.getStandortById().name)
            : funktion === MITARBEITER_SEKRETERIAT
            ? sekretariatMitarbeiter.filter(mitarbeiter => mitarbeiter.standortName === this.getStandortById().name)
            : funktion === MITARBEITER_IN_OEFFENTLICHKEITSARBEIT
            ? mitarbeiterInOeffentlichkeitsArbeit
            : [];
    };

    getProjekteOfStandort = () => {
        return Object.keys(this.state.projekte)
            .filter(projekt => {
                return this.state.projekte[projekt].standort_ids.includes(this.state.activeStandortId.toString());
            })
            .map(projekt => this.state.projekte[projekt]);
    }

    getBaubueros = () => this.state.standorte.filter(standort => {
        if (!standort.label) {
            standort.label = standort.name;
        }
        return standort.type === 'Baubüro';
    }).sort(Sortings.SORT_ALPHABETICALLY);

    setActiveStandort = standort => {
        this.setState({
            activeStandortId: standort.id,
            searchTerm: !standort.isBaubuero ? '' : standort.name,
            activeTab: !standort.isBaubuero ? TAB_ANSPRECHPARTNER : TAB_PROJEKTE
        });
    };

    setSearch = searchTerm => this.setState({ searchTerm: searchTerm || '' });

    reset = () =>
        this.setState({
            activeStandortId: '1',
            activeBaubueroId: '0',
            searchTerm: '',
            projekte_sort: [],
        });

    isSelection = () => this.state.activeStandortId > 0 || this.state.searchTerm.length > 0;

    setActiveTab = activeTab => this.setState({ activeTab });

    render() {
        if (!this.state.dataLoaded) {
            return (
                <div>
                    <h1>Loading ..</h1>
                </div>
            );
        }
        const { activeStandortId, activeTab } = this.state;

        const standort = this.getStandortById();

        return (
            <div className="standort--widget">
                <FilterView
                    parentStandort={standort.parent}
                    setActiveStandort={standort => this.setActiveStandort(standort)}
                    activeStandortId={this.state.activeStandortId}
                    standorte={this.getStandorte(Filters.STANDORTE_ALL_PARENTS)}
                    baubueros={this.getBaubueros().sort(Sortings.SORT_ALPHABETICALLY)}
                    doSearch={searchTerm => this.setSearch(searchTerm)}
                    searchTerm={this.state.searchTerm}
                    reset={() => this.reset()}
                />

                <div className="row">
                    <div className="col-lg-3">
                        {this.getStandorte().map(
                            standort =>
                                standort.id === activeStandortId && (
                                    <React.Fragment key={standort.id}>
                                        <span className="standort-type text-uppercase">{standort.type}</span>
                                        <h1 className={standort.name ? 'mb-1' : 'mb-4'}>{standort.name}</h1>
                                        {standort.parent !== null && (
                                            <p
                                                className="mb-4 c-pointer link-unstyled"
                                                onClick={() => this.setActiveStandort(this.getStandortById(standort.parent))}>
                                                Zurück zum Standort {this.getStandortById(standort.parent).name}
                                            </p>
                                        )}
                                    </React.Fragment>
                                )
                        )}

                        <Tabs standort={this.getStandortById} activeTab={activeTab} setActiveTab={tabLabel => this.setActiveTab(tabLabel)}>
                            {!standort.isBaubuero ? (
                                <div id="Ansprechpartner">
                                    <Accordeon className="mt-2">
                                        {this.getMitarbeiterOfStandort(STANDORTLEITER).map(leiter => (
                                            <Accordeon.Item key={leiter.id} headline={`Standortleiter: ${leiter.nachname} ${leiter.vorname}`}>
                                                <div className="sek">
                                                    <div>
                                                        {leiter.mail.length !== 0 && (
                                                            <React.Fragment>
                                                                <div className="d-flex align-items-center c-pointer c-mail">
                                                                    <span className="sek-item mail" />
                                                                    <a className="ml-4" href={`mailto:${leiter.mail}`}>
                                                                        {leiter.mail}
                                                                    </a>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </Accordeon.Item>
                                        ))}
                                        {this.getMitarbeiterOfStandort(MITARBEITER_SEKRETERIAT).map(mitarbeiter => (
                                            <Accordeon.Item key={mitarbeiter.id} headline={`Sekretariat: ${mitarbeiter.nachname} ${mitarbeiter.vorname}`}>
                                                <div className="sek">
                                                    {mitarbeiter.mail.length !== 0 && (
                                                        <React.Fragment>
                                                            <div className="d-flex align-items-center c-pointer c-mail">
                                                                <span className="sek-item mail" />
                                                                <a
                                                                    className="ml-4"
                                                                    href={`mailto:${
                                                                        mitarbeiter.standortID === '1' || mitarbeiter.parentStandortID === '1'
                                                                            ? 'sekretariat@ernst2-architekten.de'
                                                                            : this.getStandortById().mail
                                                                    }`}>
                                                                    {mitarbeiter.standortID === '1' || mitarbeiter.parentStandortID === '1'
                                                                        ? 'sekretariat@ernst2-architekten.de'
                                                                        : this.getStandortById().mail}
                                                                </a>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </Accordeon.Item>
                                        ))}
                                        {this.state.activeStandortId === 1 || this.state.activeStandortId === "1" ? (
                                            this.getMitarbeiterOfStandort(MITARBEITER_IN_OEFFENTLICHKEITSARBEIT).map(mitarbeiter => (
                                                <Accordeon.Item
                                                    key={mitarbeiter.id}
                                                    headline={`Öffentlichkeitsarbeit: ${mitarbeiter.nachname} ${mitarbeiter.vorname}`}>
                                                    <div className="sek">
                                                        <div className="d-flex align-items-center c-pointer c-mail">
                                                            <span className="sek-item mail" />
                                                            <a className="ml-4" href={`mailto:${mitarbeiter.mail}`}>
                                                                {mitarbeiter.mail}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordeon.Item>
                                            ))
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </Accordeon>
                                </div>
                            ) : null}
                            {standort.parent === null ? (
                                <div id="Baubüros" onClick={this.handleUpdate}>
                                    <ul className="mt-2 list-unstyled">
                                        {this.getBaubueros()
                                            .filter(baubuero => baubuero.parent === activeStandortId)
                                            .map((baubuero, index, array) => {
                                                let className = '';
                                                let lastFirstLetter = '';
                                                let currentFirstLetter = baubuero.name.charAt(0);
                                                if (index > 0) {
                                                    lastFirstLetter = array[index - 1].name.charAt(0);
                                                }
                                                if (currentFirstLetter.match(/^-{?}\d+$/) && lastFirstLetter.match(/^-{?}\d+$/)) {
                                                    className = '';
                                                } else if (currentFirstLetter === lastFirstLetter) {
                                                    className = '';
                                                } else {
                                                    className = ' mt-3';
                                                }
                                                return (
                                                    <li
                                                        className={`list-unstyled c-pointer ${className}`}
                                                        key={baubuero.id}
                                                        onClick={() => this.setActiveStandort(baubuero)}
                                                        title={baubuero.name}
                                                        value={baubuero.id}>
                                                        <a>{baubuero.name}</a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            ) : (
                                <React.Fragment />
                            )}
                            {this.getProjekteOfStandort().length !== 0 && (
                                <div id="Projekte">
                                    <ul className="list-unstyled">
                                        <Projekte projekte={this.getProjekteOfStandort()} />
                                    </ul>
                                </div>
                            )}
                        </Tabs>
                    </div>
                    <div className="col-lg-7">
                        <GoogleMapView
                            activeStandortId={activeStandortId}
                            standorte={this.getStandorte().filter(standort => standort.id === activeStandortId)}
                            baubueros={this.getStandorte().filter(standort => standort.parent === activeStandortId)}
                            isSelection={this.isSelection()}
                            clickStandortMarker={standort => this.setActiveStandort(standort)}
                            clickBaubueroMarker={standort => this.setActiveStandort(standort)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <SidebarView isSelection={this.isSelection()} activeStandort={this.getStandortById()} />
                    </div>
                </div>
            </div>
        );
    }
}
