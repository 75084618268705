import React from 'react';
import * as Store from './constants';
import '../../Storage.js';
import { getQueryVariable } from '../../getQueryVariable';

const FOCUS_PROJEKTE = 'Fokus Projekte';
const FILTER_REGIONEN = 'hauptStandortIDs';
const FILTER_TYP = 'typ';
const FILTER_STATUS = 'status';
const FILTER_SUBKATEGORIE = 'subkategorie';

const DEFAULT_FILTER = {
    [FILTER_REGIONEN]: [],
    [FILTER_STATUS]: [],
    [FILTER_SUBKATEGORIE]: [],
    [FILTER_TYP]: [],
};

export default class extends React.Component {
    state = {
        // init projects
        projects: [],
        // toggle filter view
        filterOpen: false,
        // mapping
        dataLoaded: false,
        // set active cagegories
        categoryId: FOCUS_PROJEKTE,
        subCategories: FOCUS_PROJEKTE,
        // set active filters
        activeFilter: DEFAULT_FILTER,
        // styles
        animate: false,
        navLinkActive: FOCUS_PROJEKTE,
    };

    // load data from backend and deliver to frontend
    setData = () => {
        let categoryId = getQueryVariable('kategorie', FOCUS_PROJEKTE);

        if (categoryId !== FOCUS_PROJEKTE) {

            const category = this.props.widgetData.categories.find(category => {
                return category.bez === categoryId;
            });


            if (category) {
                categoryId = category.id;
            }
        }

        this.setState({
            subCategories: this.props.widgetData.subCategories,
            dataLoaded: true,
            projects: this.convertProject(),
            categoryId: categoryId,
            navLinkActive: categoryId,
            ...(getQueryVariable('savedFilter') === null ? {} : localStorage.getObject('savedFilter', {})),
        });
    };

    componentDidMount() {
        this.setData();
    }

    // create relations from ID's to Titles, such as subCategory, standort and bauaufgabe
    convertProject = () => {
        const { images, projects, hauptStandorte, subCategories, bauAufgaben, projectUrls } = this.props.widgetData;

        let hauptStandort = {};
        hauptStandorte.forEach(s => (hauptStandort[s.s_id] = s.s_bez));

        return projects.map(project => {
            const regionen = project.standortIDs.map(p_sID => hauptStandort[p_sID] || '');

            const subkategorie = project.subCategories.map(subCategoryID => {
                const category = subCategories.find(subCategory => subCategoryID === subCategory.id.toString());
                if (category) {
                    return category.bez;
                }

                return '';
            });

            const image = images[project.id][0];

            const typ = project.bauaufgabe.map(bauAufgabeID => {
                const baufaufgabe = bauAufgaben.find(bauAufgabe => bauAufgabeID === bauAufgabe.id.toString());
                if (baufaufgabe) {
                    return baufaufgabe.bez;
                }
                return '';
            });

            return {
                ...project,
                regionen,
                status: [Store.getProjectStatus(project.status)],
                subkategorie,
                url: projectUrls[project.id],
                image: image ? `https://intranet.ernst2-architekten.de/uploads/projekte_internet/${project.id}/${image.pb_bild}` : '',
                typ,
            };
        });
    };

    // if the user click on a project for the detail view the current filters will be saved for a redirect
    saveCurrentFilter = () => {
        const { activeFilter, navLinkActive, categoryId } = this.state;
        localStorage.setObject('savedFilter', {
            categoryId,
            activeFilter,
            navLinkActive,
            filterOpen: true,
        });
    };

    // toggle filter view open / hide
    toggleFilter = () => this.setState({ filterOpen: !this.state.filterOpen });

    // set active category and handle animation
    setCategoryId = id => {
        this.setState({
            animate: true,
            navLinkActive: id,
            activeFilter: {
                ...DEFAULT_FILTER,
            },
        });
        setTimeout(
            () =>
                this.setState({
                    categoryId: id,
                    animate: false,
                }),
            500
        );
    };

    getCategorySubTypes = () => {
        if (this.state.categoryId === FOCUS_PROJEKTE) {
            return [
                {
                    id: 0,
                    bez: 'Fokus',
                    parent: { bez: 'Fokus' },
                    projects: this.getProjects(),
                },
            ];
        }

        const parent = this.props.widgetData.categories.find(category => parseInt(category.id) === parseInt(this.state.categoryId));
        return this.props.widgetData.subCategories
            .filter(category => category.parent_id === this.state.categoryId)
            .map(category => ({
                ...category,
                parent,
                projects: this.getProjects().filter(project => {
                    if (!isNaN(category.id)) {
                        category.id = category.id.toString()
                    }

                    return project.subCategories.includes(category.id);
                }),
            }));
    };

    // handle filtering and return filtered projects or non filtered projects
    getProjects = () => {
        const activeFilter = this.state.activeFilter;
        return this.state.projects
            .filter(project => {
                if (this.state.categoryId === FOCUS_PROJEKTE) {
                    return project.focus === 1;
                } else {
                    return true;
                }
            })
            .filter(project => {
                if (activeFilter[FILTER_REGIONEN].length > 0) {
                    if (activeFilter[FILTER_REGIONEN].filter(region => project[FILTER_REGIONEN].includes(region.toString())).length === 0) {
                        return false;
                    }
                }
                if (activeFilter[FILTER_STATUS].length > 0) {
                    if (activeFilter[FILTER_STATUS].filter(status => project[FILTER_STATUS].includes(status)).length === 0) {
                        return false;
                    }
                }
                if (activeFilter[FILTER_TYP].length > 0) {
                    if (activeFilter[FILTER_TYP].filter(typ => project[FILTER_TYP].includes(typ)).length === 0) {
                        return false;
                    }
                }
                if (activeFilter[FILTER_SUBKATEGORIE].length > 0) {
                    if (activeFilter[FILTER_SUBKATEGORIE].filter(typ => project[FILTER_SUBKATEGORIE].includes(typ)).length === 0) {
                        return false;
                    }
                }
                return true;
            });
    };

    // handle active options from checkboxes and send it trough setActiveFilter
    setFilter = (type, option) => {
        const { activeFilter } = this.state;

        this.state.activeFilter[type] = activeFilter[type].includes(option)
            ? [...activeFilter[type].filter(el => el !== option)]
            : [...activeFilter[type], option];

        this.setState(this.state);
    };

    getFilterItems = () => {
        const { hauptStandorte, bauAufgaben, subCategories } = this.props.widgetData;
        const { activeFilter, navLinkActive } = this.state;
        return [
            {
                label: 'Regionen',
                id: 0,
                items: hauptStandorte.map(standort => ({
                    name: standort.s_bez,
                    toggle: () => this.setFilter(FILTER_REGIONEN, standort.s_id),
                    enabled: activeFilter[FILTER_REGIONEN].includes(standort.s_id),
                })),
                className: 'col-12 col-md-6 col-lg-3',
            },
            {
                label: 'Status',
                id: 1,
                items: Store.STATUS.map(status => ({
                    name: Store.getProjectStatus(status),
                    toggle: () => this.setFilter(FILTER_STATUS, Store.getProjectStatus(status)),
                    enabled: activeFilter[FILTER_STATUS].includes(Store.getProjectStatus(status)),
                })),
                className: 'col-12 col-md-6 col-lg-3',
            },
            {
                label: 'Typ',
                id: 2,
                items: bauAufgaben.map(aufgabe => ({
                    name: aufgabe.bez,
                    toggle: () => this.setFilter(FILTER_TYP, aufgabe.bez),
                    enabled: activeFilter[FILTER_TYP].includes(aufgabe.bez),
                })),
                className: 'col-12 col-md-6 col-lg-3 typ-list',
            },
            {
                label: 'Subkategorie',
                id: 3,
                items: subCategories.map(subCategory => {
                    if (subCategory.parent_id === navLinkActive) {
                        return {
                            name: subCategory.bez,
                            toggle: () => this.setFilter(FILTER_SUBKATEGORIE, subCategory.bez),
                            enabled: activeFilter[FILTER_SUBKATEGORIE].includes(subCategory.bez),
                        };
                    }
                    return null;
                }),
                className: 'col-12 col-md-6 col-lg-3 subkat-list',
            },
        ];
    };

    render() {
        const { categories } = this.props.widgetData;
        const { filterOpen, dataLoaded, animate, navLinkActive } = this.state;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        {dataLoaded && (
                            <ul className="list-unstyled d-flex flex-column flex-lg-row mb-lg-0 mb-3 category-links w-100">
                                <li
                                    onClick={() => this.setCategoryId(FOCUS_PROJEKTE)}
                                    className={`d-flex align-items-center text-uppercase ml-0 ml-lg-3 font-weight-bold pt-1 pb-1 pl-2 pr-2 project-type-label pointer ${
                                        navLinkActive === FOCUS_PROJEKTE ? 'type-active' : ''
                                    }`}>
                                    <span>{FOCUS_PROJEKTE}</span>
                                </li>
                                {categories.map(type => (
                                    <li
                                        onClick={() => this.setCategoryId(type.id)}
                                        key={type.id}
                                        className={`d-flex align-items-center text-uppercase ml-0 ml-lg-3 font-weight-bold pt-1 pb-1 pl-2 pr-2 project-type-label pointer ${
                                            navLinkActive === type.id ? 'type-active' : ''
                                        }`}>
                                        <span>{type.bez}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <hr />
                <div className={`project-filter row ${filterOpen ? 'mb-4 mt-5' : 'hide-filter'}`}>
                    {this.getFilterItems().map(filter =>
                        filter.id === 3 && navLinkActive === FOCUS_PROJEKTE ? (
                            <React.Fragment />
                        ) : (
                            <div key={filter.label} className={`d-flex flex-column justify-content-start ${filter.className}`}>
                                <h5 className="m-0 font-weight-bold">{filter.label}</h5>
                                <hr className="w-100" />
                                <ul className="list-unstyled filter-list" style={{
                                    maxHeight: ((filter.items.length / 2) * 32) + 30,
                                }}>
                                    {filter.items.map(
                                        item =>
                                            item && (
                                                <li key={item.name} className="d-flex justify-content-start align-items-center list-item">
                                                    <div className="widget widget-checkbox">
                                                        <fieldset className="checkbox_container">
                                                            <span onClick={() => item.toggle()}>
                                                                <input readOnly={true} type="checkbox" className="checkbox" checked={item.enabled} />
                                                                <label className="pointer">{item.name}</label>
                                                            </span>
                                                        </fieldset>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        )
                    )}
                </div>
                <hr className={`${filterOpen ? '' : 'd-none'}`} />
                <div className="row mt-2 mb-2">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="pointer open-filter-btn" onClick={() => this.toggleFilter()}>
                            {filterOpen ? 'Filter schliessen' : 'Filter öffnen'}
                        </button>
                        {filterOpen && (
                            <button className="pointer reset-filter-btn" onClick={() => this.setState({ activeFilter: { ...DEFAULT_FILTER } })}>
                                Filter zurücksetzen
                            </button>
                        )}
                    </div>
                </div>
                {this.getCategorySubTypes()
                    .filter(item => item.projects.length > 0)
                    .map(item => (
                        <div key={item.id} className={`row mt-5 ${animate ? 'slide-out' : 'slide-in'}`}>
                            <div className="col-12 category-type">
                                {this.state.categoryId !== FOCUS_PROJEKTE && (
                                    <React.Fragment>
                                        <span className="text-uppercase text-success">{item.parent.bez}</span>
                                        <h1 className="m-0">{item.bez}</h1>
                                    </React.Fragment>
                                )}
                            </div>
                            {dataLoaded &&
                                item.projects.map(project => (
                                    <div
                                        key={project.pi_id}
                                        className="col-md-3 col-12 d-flex flex-column justify-content-between align-items-start mt-5 project">
                                        {project.image !== '' ? (
                                            <div
                                                className="project-image mt-2 border border-light"
                                                style={{
                                                    backgroundImage: `url(${project.image})`,
                                                }}>
                                                <a onClick={() => this.saveCurrentFilter()} className="open-project-detail" href={project.url} />
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex justify-content-center align-items-center w-100 h-100 border border-light"
                                                style={{ minHeight: 220 }}>
                                                <a onClick={() => this.saveCurrentFilter()} className="open-project-detail" href={project.url} />
                                                <span className="lead">kein Bild vorhanden :(</span>
                                            </div>
                                        )}
                                        <div
                                            className="d-inline-block mt-3"
                                            style={{
                                                height: 80,
                                            }}>
                                            <h4 className="m-0 text-muted">{project.titel}</h4>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>
        );
    }
}
